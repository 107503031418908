import Api from '../api/Api';
const srvApi = (endpoint,query) => {
	const get = async (filtro) => {
		return await Api(query).all(endpoint,filtro).then(d => d.data);
	};

	const getbyId = async (id) => {
		return await Api(query).show(endpoint,id).then(r => r.data.data);
	};
	
	const getbyDesc = async (descr) => {
		return await Api(query).show(endpoint + '/descr',descr).then(r => r.data.data);
	};
	
	const edit = async (body) => {
		var result;
		var idEntidad;
		console.log(body);
		switch (endpoint) {
			case 'venta': idEntidad = body.ventas.id; break;
			case 'compra': idEntidad = body.compras.id; break;
			case 'ncf': idEntidad = body.tipo; break;
			case 'ingreso': idEntidad = body.recibo.id; break; 
			case 'ajusteinv': idEntidad = body.master.id; break;
			case 'cheque': idEntidad = body.cheque.id; break;
			case 'nota': idEntidad = body.nota.id; break;
			default: idEntidad = body.id; break;
		}

		if (idEntidad===0){
			result = await Api(query).store(endpoint, body).then(r => r.data );
		} else {
			result  = await Api(query).edit(endpoint, idEntidad, body).then(r => r.data)
		}
		return result;
	};

	const post = async (body) => {
		return await Api(query).store(endpoint + '/filter', body).then(r => r.data );
	};

	const del = async (id) => {
		return await Api(query).del(endpoint+'/del',id).then(r => r.data);
	}

	const Login = async (login) => {
		return await Api(query).store(endpoint,login).then(r=>r.data);
	}

    return { get, getbyId, getbyDesc, edit, del, Login,post }
};
export default srvApi