import axios from "axios";

const ApiUrlDev = 'https://localhost:5001/api';
const ApiUrlProd = 'https://api.fsanchez.cyou/api';

    const http = axios.create({
        baseURL: process.env.NODE_ENV === 'development' ? ApiUrlDev : ApiUrlProd,
        headers: { "Content-Type": "application/json" }
    });

const Api = (query) => {
    http.interceptors.request.use(
        (config)=>{
            const user = JSON.parse(localStorage.getItem('user'));
            if (user)
                config.headers.Authorization = `Bearer ${user.token}`; 
            return config;
        },
        (e)=>{
            return Promise.reject(e);
        }
    );    

    http.interceptors.response.use(
        (res)=> {return res;},
        async (e)=>{
            const originalConfig = e.config;
            if (originalConfig.url !== "/account" && e.response){
                if(e.response.status===401 && !originalConfig._retry){
                    originalConfig._retry=true;

                    try{
                        const user = JSON.parse(localStorage.getItem('user'));
                        await http.post("/account/refresh",{
                            "AccessToken": user.token,
                            "RefreshToken": user.rToken
                        }).then(
                            r => {
                                if (r.data.isSuccess){
                                    const { accessToken,refreshToken } = r.data.data;
                                    if (accessToken && refreshToken)
                                    {
                                        user.token = accessToken;
                                        user.rToken = refreshToken;
                                        localStorage.setItem('user', JSON.stringify(user));
                                    }
                                }
                                else{
                                    localStorage.setItem('user', null);
                                }
                                    
                            }
                        ).catch( e=> {
                            localStorage.setItem('user', null);
                        })
                        return http(originalConfig);
                    } catch (e){
                        return Promise.reject(e);
                    }
                }
                else if(e.response.status===401 && originalConfig._retry){
                    localStorage.setItem('user', null);
                }
            }
            return Promise.reject(e);
        }
    );

    const all = async (END_POINT,filtro) =>  {
        return await http.get(END_POINT, { data: filtro  }); //
    };
    
    const show = async (END_POINT,id) => {
        return await http.get(`${END_POINT}/${id}`, query);
    };
    
    const store = async (END_POINT,data) => {
        return await http.post(END_POINT, data);
    };

    const edit = async (END_POINT,id,data)=> {
        return await http.put(`${END_POINT}/${id}`, data);
    };

    const del = async (END_POINT,id) => {
        return await http.delete(`${END_POINT}/${id}`);
    }
    return { all, show , store, edit, del}
}
export default Api
