const initialClt = {
                "idCompania": 1, "id": 0, "nombre": "", "negocio": "", "telefono": "",
                "cedula": "",    "direccion1": "",    "direccion2": "",    "direccion3": "",    "estadoCivil": "S",    "fechanac": "",    "fechaing": "",
                "sexo": "M",    "fechaBaja": null,    "ocupacion": null,    "tipo": "",    "observacion": "",    "precioFactura": 3,    "limitecre": null,    "ncfTipo": 0,
                "diasCredito": 0,    "vendedor": 0,    "tipoident": 1,    "comision": 0    };

const initialProd = {
                "compania": 1,    "id": 0,    "descripcion": "",    "grupo": 0,    "ubicacion": "",    "marca": "",    "capacidad": "",    "existencia": 0,    "pcosto": 0,    "flete": 0,
                "descarga": 0,    "costoprome": 0,    "pventa": 0,    "exismaxima": 0,    "exisminima": 0,    "activo": 1,    "usuario": "",    "mitbi": "N",    "prec1": 0,    "prec2": 0,
                "prec3": 0,    "existInicial": 0,    "apagar": 0,    "ventaCtaInventario": "",    "subunidad": "",    "subunidadCant": 1,    "ctaAlterna": "",    "usarCtaAlterna": false,    
                "noUsarControlExist": true,    "ctaAlterna2": ""
}

const initialFactM = {
                "id": 0,    "idCliente": 0,    "fecha": "2022-04-12T00:00:00-04:00",    "tipodoc": 1,    "idCondicion": 1,    "usuario": "",    "forma": 0,    "nombre": "",    "posteo": false,    "anulado": false,
                "ncf": "",    "conduce": 0,    "vendedor": 0,    "ncfTipo": 0,    "idNcf": 0,    "hora": "",    "diascredito": 0}
const initialCompra = {
    "id": 0,    "idSuplidor": 0,    "fecha": "2022-04-12T00:00:00-04:00",  "factura":0,  "tipodoc": 2, "condicion": 0, "usuario": "", "posteo": false, "anular": false,
    "ncf": "", "fechanula": null}                

const initialSup = {  "idCompania":1, "id":0, "nombre":"", "telefonos":"", "direccion":"", "tipoIdent":3, "rnc":"", "observacion":"", "tiposup":0};
   
const initialFactD = { "codigo": '',  "cantidad": '',  "precioc": 0, "preciov": 0, "itbi": 0, "desc": 0, "preciocp": 0, 
                        "precioLista": 0, "orden": 0, "llevaItbi":'N', "descripcion": ""}
const initialCompraD = { "idProducto": '',  "cantidad": '',  "precio": 0,  "citbi": 0, "secuencia": 0, "descripcion": "", "llevaItbi":'N',}
const cltDefaultFact = {    'id':0, 'nombre':'', 'precioFactura':0, 'vendedor':0, 'limitecre':0, 'ncfTipo':0,'idCliente':0}
const initialComp = { "id":0,  "nombre":"",  "dir":"",  "rnc":"", "tel":""}
const initialNcf = { "compania":1,  "numero":0,  "autorizacion":"",  "fecha":null, "tipo":0, "serie":"", "rangoIni":0, "rangoFin":0, "ultimo":0, "vence":false, "fechaVence":null}
const initialTProd = {"idCompania":1,"id":0,"nombre":""}
const initialTNcf = {"idCompania":1,"id":0,"nombre":""}
const initialTClt = {"idCompania":1,"id":0,"nombre":""}
const initialTSup = {"idCompania":1,"id":0,"nombre":""}

const initialRec = {"idCompania":1,"id":0,"cliente":0,"fecha":"","nombre":"","monto":0.0,"concepto":"","banco":"","tipo":"","usuario":"","posteo":false,"cobrador":0,"referencia":""}

const camposClt = [
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico", ancho:"10%"},
    { nombre: 'nombre', titulo: "Nombre",justificar:"start",tipo:"texto", ancho:"40%"},
    { nombre: 'telefono', titulo: "Teléfono",justificar:"start",tipo:"texto", ancho:"15%"},
    { nombre: 'cedula', titulo: "Cedula",justificar:"start",tipo:"texto", ancho:"15%"},
    { nombre: 'tiponombre', titulo: "Tipo Cliente",justificar:"start",tipo:"texto", ancho:"20%"}
];

const camposRptClt = [
    { nombre: 'id', titulo: "ID",justificar:"right",tipo:"numerico", ancho:"1%"},
    { nombre: 'nombre', titulo: "Nombre",justificar:"left",tipo:"texto", ancho:"30%"},
    { nombre: 'telefono', titulo: "Teléfono",justificar:"left",tipo:"texto", ancho:"15%"},
    { nombre: 'cedula', titulo: "Cedula",justificar:"left",tipo:"texto", ancho:"15%"},
    { nombre: 'tiponombre', titulo: "Tipo Cliente",justificar:"left",tipo:"texto", ancho:"20%"}
];
    
const camposProd = [
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'descripcion', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'tipo', titulo: "Tipo",justificar:"start",tipo:"texto"},
    { nombre: 'estatus', titulo: "Activo",justificar:"start",tipo:"siono"},
]; 

const camposSup = [
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'telefonos', titulo: "Teléfono",justificar:"start",tipo:"texto"},
    { nombre: 'tipoSuplidor', titulo: "Tipo",justificar:"start",tipo:"texto"},
]; 

const camposVenta = [
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'fecha', titulo: "Fecha",justificar:"start",tipo:"fecha"},
    { nombre: 'nombre', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'condDesc', titulo: "Condición",justificar:"start",tipo:"texto"},
    { nombre: 'anulado', titulo: "Nula",justificar:"start",tipo:"siono"},
]; 

const camposNcf = [
    { nombre: 'numero', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'tipo', titulo: "Tipo",justificar:"start",tipo:"numerico"},
    { nombre: 'nombreTipo', titulo: "Tipo Descripción",justificar:"start",tipo:"texto"},
    { nombre: 'serie', titulo: "Serie",justificar:"start",tipo:"texto"},
    { nombre: 'vence', titulo: "Vence",justificar:"start",tipo:"siono"}
]; 

const camposTClt =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Descripción",justificar:"start",tipo:"texto"}
];

const camposTProd =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Descripción",justificar:"start",tipo:"texto"}
]

const camposTNcf =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Descripción",justificar:"start",tipo:"texto"}
];

const camposTSup =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Descripción",justificar:"start",tipo:"texto"}
];

const camposIng =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'cliente', titulo: "Cliente",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'fecha', titulo: "Fecha",justificar:"start",tipo:"fecha"},
    { nombre: 'monto', titulo: "Monto",justificar:"end",tipo:"monto"},
];
const camposCompra =  
[
    { nombre: 'id', titulo: "Entrada",justificar:"start",tipo:"numerico"},
    { nombre: 'factura', titulo: "Factura",justificar:"start",tipo:"numerico"},
    { nombre: 'fecha', titulo: "Fecha",justificar:"start",tipo:"fecha"},
    { nombre: 'supNombre', titulo: "Suplidor",justificar:"start",tipo:"texto"},
    { nombre: 'condDesc', titulo: "Condición",justificar:"start",tipo:"texto"},
    { nombre: 'Anular', titulo: "Anulado",justificar:"start",tipo:"siono"},
];

const camposAjusteInv =  
[
    { nombre: 'id', titulo: "ID",justificar:"start",tipo:"numerico"},
    { nombre: 'fecha', titulo: "Fecha",justificar:"start",tipo:"fecha"},
    { nombre: 'nulo', titulo: "Anulado",justificar:"start",tipo:"siono"}
];

const camposCK =  
[
    { nombre: 'numero', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'fecha', titulo: "Fecha",justificar:"start",tipo:"fecha"},
    { nombre: 'nombre', titulo: "A nombre de",justificar:"start",tipo:"texto"},
    { nombre: 'idBanco', titulo: "IdBanco",justificar:"end",tipo:"numerico"},
    { nombre: 'banco', titulo: "Banco",justificar:"start",tipo:"texto"},
    { nombre: 'monto', titulo: "Monto",justificar:"end",tipo:"monto"}
];

const camposNota =  
[
    { nombre: 'numero', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'tipoNombre', titulo: "Tipo",justificar:"center",tipo:"texto"},
    { nombre: 'fecha', titulo: "Fecha",justificar:"start",tipo:"fecha"},
    { nombre: 'clisup', titulo: "Cliente",justificar:"start",tipo:"texto"},
    { nombre: 'ncf', titulo: "Comprobante",justificar:"start",tipo:"texto"}
];

const camposBanco =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'mascarilla', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'cuenta', titulo: "cuenta",justificar:"start",tipo:"texto"}
];

const camposCatalogo =  
[
    { nombre: 'ctacont', titulo: "Cuenta",justificar:"start",tipo:"texto"},
    { nombre: 'descripcion', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'detalle', titulo: "Detalle",justificar:"start",tipo:"siono"}
];

const camposVendedor =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'telefono', titulo: "Teléfono",justificar:"start",tipo:"texto"}
];
const camposCobrador =  
[
    { nombre: 'id', titulo: "ID",justificar:"end",tipo:"numerico"},
    { nombre: 'nombre', titulo: "Nombre",justificar:"start",tipo:"texto"},
    { nombre: 'telefono', titulo: "Teléfono",justificar:"start",tipo:"texto"}
];

export { 
    initialClt, initialProd,initialFactM,initialFactD,initialSup,initialNcf,initialCompra,initialCompraD,
    cltDefaultFact,camposClt,camposProd,camposSup,camposVenta,initialComp,camposNcf,camposTClt,initialTClt,
    initialTProd,camposTProd,initialTNcf,camposTNcf,initialTSup,camposTSup,camposIng,initialRec,camposAjusteInv,
    camposCompra,camposCK,camposBanco,camposCatalogo,camposVendedor,camposCobrador,camposNota,
    camposRptClt
};