import { useEffect, useState } from "react";
const Filtro = ({visible,mostrar,camposFiltro,genFiltro,entidad}) =>  {
    const [data,setData] = useState([]);
    const [operacion,setOp] = useState([]);
    const [orden, setOrden] = useState();
    const [ordenby, setOrdenby] = useState();

    const buscarTipo = (tipo) =>{
        var result = "text";
        switch (tipo) {
            case "fecha":
                result="date";
                break;
            case "siono":
                result="text";
                break;
            case "numerico": 
            case "monto":
                result="number";
                break;
            default:
                result="text";
                break;
        }
        return result;
    }
    var compTxt  = [{nombre: "*"}];
    var compVal = [{nombre:"<"},{nombre:">"},{nombre:"<="},{nombre:">="}];

    const handleOp = (e) => {
        e.preventDefault();
        setOp({...operacion, [e.target.name]: e.target.value});
    }

    const handleOrden = (e) => {
        e.preventDefault();
        setOrden(e.target.value);
    }
    const handleOrdenby = (e) => {
        e.preventDefault();
        setOrdenby(e.target.value);
    }

    const handleChange = (e) => {
        e.preventDefault();
        var filteredArray = Array.isArray(data) ? data.filter(x => x.Columna !== e.target.name) : [];
        let valor='';
        switch (e.target.name) {
            case 'mitbi':
                valor=e.target.value==='1'?'S':'N'
                break;
            default:
                valor=e.target.value
                break;
        }
        let x = { Columna: e.target.name, Valor: valor, Comparador:"=" };
        if (e.target.value !== '') filteredArray.push(x);

        setData(filteredArray);
    }

    const Buscar = () =>{
        let myFiltro;
        let myOrden;

        data.map(obj => {
            for (let [key, value] of Object.entries(operacion)) {
                if (obj.Columna===key)
                    obj.Comparador=value
            }
            return obj
        });

        if (orden && ordenby)
            myOrden = {
                "columna":orden,
                "ascendente":ordenby
            }
        else
            myOrden=null;

        myFiltro = {
            "filtro":data?.length>0?data:null,
            "orden": myOrden
        }
        genFiltro(myFiltro);
        mostrar();
    }

    useEffect(() => {
        setData([]);
        setOp([]);
        setOrden("");
        setOrdenby("true");
    },[visible])


    return (
        <>
        <div className="offcanvas offcanvas-end show" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel"
            style={{visibility:visible===true?'visible':'hidden',zIndex:"9998"}}>
            <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">Buscar Por :</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={mostrar}></button>
            </div>
            <div className="offcanvas-body">
            <form>
                {visible && camposFiltro.map((f,i) => {
                    return (
                        <div key={i+entidad} className="row">
                            <div className="col-10 p-0">
                                <label htmlFor={`txt${f.titulo}-${f.nombre}`} className="form-label">{f.titulo}</label>
                                {f.tipo==="siono" &&
                                    <select id={`txt${f.titulo}-${f.nombre}`} className="form-control" onChange={handleChange} name={f.nombre} >
                                        <option value="">Seleccionar</option>
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </select>
                                }
                                {f.tipo!=="siono" &&
                                <input
                                    type={buscarTipo(f.tipo)} onChange={handleChange}
                                    className="form-control" id={`txt${f.titulo}-${f.nombre}`} name={f.nombre}/>
                                }
                            </div>
                            <div className="col-2 p-0 text-center" >
                                <label htmlFor={`op${f.titulo}-${f.nombre}`} className="form-label">...</label>
                                <select id={`op${f.titulo}-${f.nombre}`} name={f.nombre} defaultValue="="
                                    className="form-control text-center"
                                    onChange={handleOp}>
                                        <option key={i} value={"="}>{"="}</option>
                                    {f.tipo==="texto" && compTxt.map((c,i) => {
                                        return (
                                            <option key={i} value={c.nombre}>{c.nombre}</option>
                                        )
                                    })}
                                    {f.tipo!=="texto" && f.tipo!=="siono" && compVal.map((c,i) => {
                                        return (
                                            <option key={i} value={c.nombre}>{c.nombre}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    )
                })}
                <div className="row">
                    <div className="col-10 p-0" >
                        <label htmlFor="opSort" className="form-label">Ordenar por: </label>
                        <select id="opSort" className="form-control" onChange={handleOrden} value={orden}>
                            <option value="">Seleccionar</option>
                            {camposFiltro.map((c,i) => {
                                return (
                                    <option key={i} value={c.nombre}>{c.titulo}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-2 p-0 text-center" >
                        <label htmlFor="opSortby" className="form-label">...</label>
                        <select id="opSortby" className="form-control text-center" onChange={handleOrdenby} value={ordenby}>
                            <option value="true">Asc</option>
                            <option value="false">Desc</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3">
                    <button type="button" className="btn btn-outline-primary" onClick={Buscar}>Filtrar</button>
                </div>
            </form>
            </div>
        </div>
        <div className={visible===true?'modal-backdrop fade show':''}></div>
        </>
     );
}
export default Filtro ;