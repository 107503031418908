import {  useCallback, useEffect, useRef, useState} from "react";
import srvApi from "../../Services/srvApi";
import moment from "moment";
import Filtro from "./Filtro";
import Skeleton from 'react-loading-skeleton'
import NoRecordFound from '../../views/NoRecordFound'

const FsReport = ({entidad, campos, nombre}) => {
  const [ data, setData] = useState([]);
  const [ loading, setLoading] = useState(false);
  const [ verFiltro, SetVerFiltro] = useState(false);

  const filtro = useRef(null);
  const orden = useRef(null);

  const lapagina = useRef(1);
  const Buscar = useCallback(async () => {
    let myfiltro;
    let pageSize=20;
    setLoading(true);
    if (filtro.current!==null || orden.current!==null)
      pageSize=10000;

    myfiltro = {
        "PageNumber": lapagina.current,
        "PageSize": pageSize,
        "Filtros": filtro.current,
        "Orden":orden.current
      }

    const params = new URLSearchParams();
    try {
      const result = await srvApi(entidad,params).post(myfiltro);

      if (result.isSuccess){
        setData(result.data);
        setLoading(false);    
      }
      else
      {
        setData([]);
        setLoading(false);    
      }
      
    } catch (error) {
      setData([]);
      setLoading(false);
    }
    
  },[entidad]);

  useEffect(() => {
    const controller = new AbortController();
    lapagina.current=1;
    filtro.current=null;
    orden.current=null;
    
    Buscar();  
    return ()=>{
      controller.abort();
    }
      
  }, [entidad,Buscar]);

  const mostrarFiltro = () => {
    SetVerFiltro(!verFiltro);
  }

  const setFiltro = (f)=>{
    filtro.current = f.filtro;
    orden.current = f.orden;
    Buscar();
  }
  
  const formatea = (tipo, valor) => {
    var result;
    const verdadero  = valor===1 || valor===true?true:false; 
    const sionoObj = (
        verdadero?"Si":"No" 
    );

    switch (tipo) {
      case 'fecha':
        result = moment(valor).format('YYYY-MM-DD');
        break;
      case 'siono':
          result = sionoObj;
        break;
      case 'monto':
        if (valor)
        result=valor.toLocaleString('en-US', { 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
        });
        break;
      default:
        result = valor;
        break;
    }
    return result;
  }

  return (
    <>
          <div className="fsprintable">
            <div className="report-header">
              <table style={{width:"100%"}} >
                <tbody>
                  <tr>
                    <td style={{textAlign:"left"}}>Farmacia Mella</td>
                    <td style={{textAlign:"right"}}><div className="page-number"></div></td>
                  </tr>
                  <tr>
                    <td style={{textAlign:"left"}}>Avenida anacaona no. 5 la zurza, Santo Domingo, Republica dominicana</td>
                    <td style={{textAlign:"right"}}>10/06/2023</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{nombre}</td>
                  </tr>
                </tbody>
              </table>
            </div> 
          </div>
      <div className="content " >


        <div className="row " >
          <Filtro visible={verFiltro}  mostrar = {mostrarFiltro} genFiltro = {setFiltro} camposFiltro = {campos} entidad={entidad}/>
          <div className = "col-12 me-2 frms pb-2 noprint"> 
            <button type="button" className="btn btn-sm btn-outline-primary me-1" onClick={() => {window.print()}}>
              Imprimir
            </button>
            <button type="button" className="btn btn-sm btn-outline-primary"
              data-bs-toggle="offcanvas" 
              data-bs-target="#offcanvasRight" 
              aria-controls="offcanvasRight"
              onClick={mostrarFiltro}
            > Filtrar
            </button>
          </div>
          {/* <div className="col-12"> */}
          {/* </div> */}
        </div>
        
        {/* <div className="report-footer noprint">
          I'm The Footer
        </div> */}

        <table style={{width:"100%"}}>
          <thead>
            <tr>
              <td>
                {/* <!--place holder for the fixed-position header--> */}
                <div className="report-header-space" ></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="report">
                  {loading ? 
                  <table style={{width:"100%"}}>
                    <thead>
                      <tr style={{border:"1px solid lightgray"}}>
                        {campos.map((c,i) => 
                          <th key={i}
                            // style={{width:c.ancho, textAlign:c.justificar, padding:"2px"}}>
                              style={{minWidth:c.ancho,textAlign:c.justificar, padding:"2px"}}  >                              
                            {c.titulo}
                          </th>
                        )} 
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from(Array(10).keys()).map(c=> 
                        <tr key={c} style={{whiteSpace: "nowrap"}}>
                          {
                            campos.map((cc,i) => 
                              <td 
                                key={i}
                                style={{textAlign:cc.justificar, padding:"2px"}} 
                              > 
                                <Skeleton />
                              </td> )}
                        </tr>
                      )}
                    </tbody>
                  </table> :
                  data.length>0 ?
                  <table style={{width:"100%"}}>
                    <thead>
                      <tr style={{border:"1px solid lightgray"}}>
                        {campos.map((c,i) => 
                          <th key={i}
                              style={{minWidth:c.ancho,textAlign:c.justificar, padding:"2px"}}  >                              
                            {c.titulo}
                          </th>
                        )} 
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(c=> 
                        <tr key={c.id || c.numero || c.ctacont} style={{whiteSpace: "nowrap"}}>
                          {
                            campos.map((cc,i) => 
                              <td 
                                key={i}
                                style={{textAlign:cc.justificar, padding:"2px"}} 
                              > 
                                {formatea(cc.tipo, c[cc.nombre]) }
                              </td> )}
                        </tr>
                      )}
                    </tbody>
                  </table>:
                  data.length===0 && <NoRecordFound />}
                </div>
              </td>
            </tr>
          </tbody>
          {/* <tfoot  className="rfooter">
            <tr>
              <td>
                <div className="report-footer-space"></div>
              </td>
            </tr>
          </tfoot> */}
        </table>
      </div>
    </>
  );
}

export default FsReport;
