import React from "react";

import { MsgProvider } from "./context/MsgContext";
// import { LoginProvider } from "./context/LoginContext";
import { SkeletonTheme } from 'react-loading-skeleton'
import DynamicRoutes from "./components/DynamicRoutes"
import './assets/customcolor.scss';
import 'react-loading-skeleton/dist/skeleton.css'
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      {/* <LoginProvider> */}
        <SkeletonTheme baseColor="#ccd3db" highlightColor="#d9e9fa">
          <MsgProvider>
            <DynamicRoutes/>
          </MsgProvider>
          </SkeletonTheme>
      {/* </LoginProvider> */}
    </AuthProvider>
  );
}

export default App;
