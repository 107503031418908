const NoRecordFound = () => {
  
  return (
    
    <div className="top-50 start-50 text-center">
      <hr/>
      <h1 className="display-1 fw-bold">...</h1>  
      <p className="fs-3">No existen registros con esa condición.</p>
      <hr/>
    </div>
  )
}

export default NoRecordFound