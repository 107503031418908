import { FaSpider } from "react-icons/fa";
import { lazy } from "react";
import {
    camposClt,
    camposProd,
    camposSup,
    camposVenta,
    camposNcf,  
    camposTClt,
    camposIng,
    camposAjusteInv,
    camposCompra,
    camposCK,
    camposBanco,
    camposCatalogo,
    camposVendedor,
    camposCobrador,
    camposNota,
    camposRptClt
  } from './variables/entidades';
import FsReport from "./components/Controles/FsReport";

const Table = lazy(() => import("./components/Controles/FsTable"));
const MCliente= lazy(() => import("./components/Mnts/MntCliente"));
const MProd= lazy(() => import("./components/Mnts/MntProducto"));
const MSup= lazy(() => import("./components/Mnts/MntSuplidor"));
const MComp= lazy(() => import("./components/Mnts/MntCompania"));
const MNcf= lazy(() => import("./components/Mnts/MntNcf"));
const MTClt= lazy(() => import("./components/Mnts/MntTCliente"));
const MTProd= lazy(() => import("./components/Mnts/MntTProd"));
const MTNcf= lazy(() => import("./components/Mnts/MntTNcf"));
const MTSup= lazy(() => import("./components/Mnts/MntTSup"));
const MBanco= lazy(() => import("./components/Mnts/MntBanco"));
const MCatalogo= lazy(() => import("./components/Mnts/MntCatalogo"));
const MVendedor= lazy(() => import("./components/Mnts/MntVendedor"));
const MCobrador= lazy(() => import("./components/Mnts/MntCobrador"));
const MNota= lazy(() => import("./components/Mnts/MntNotaCxC"));
const Dashboard = lazy(() => import("./views/Dashboard"));

const TFact= lazy(() => import("./components/Trans/MntFactura"));
const TRec= lazy(() => import("./components/Trans/MntRecibo"));
const TAjusteInv= lazy(() => import("./components/Trans/MntAjusteInv"));
const TCompra= lazy(() => import("./components/Trans/MntCompra"));
const TCheque= lazy(() => import("./components/Trans/MntCheque"));

const TableClt = <Table campos = {camposClt} detalle="cliente" entidad="cliente" nombre="Clientes" />
const TableProd = <Table campos = {camposProd} detalle="producto" entidad="prod" nombre="Productos"/>
const TableSup = <Table campos = {camposSup} detalle="suplidor" entidad="suplidor" nombre="Suplidores"/>
const TableVenta = <Table campos = {camposVenta} detalle="venta" entidad="venta" nombre="Ventas"/>
const TableNcf = <Table campos = {camposNcf} detalle="ncf" entidad="ncf" nombre="NCFs"/>
const TableTClt = <Table campos = {camposTClt} detalle="tcliente" entidad="tipocliente" nombre="Tipos de Clientes"/>
const TableTProd = <Table campos = {camposTClt} detalle="tprod" entidad="tipoprod" nombre="Tipos de Productos"/>
const TableTSup = <Table campos = {camposTClt} detalle="tsup" entidad="tiposuplidor" nombre="Tipos de Suplidores"/>
const TableTncf = <Table campos = {camposTClt} detalle="tncf" entidad="tiponcf" nombre="Tipos de NCF"/>
const TableIng = <Table campos = {camposIng} detalle="recibo" entidad="ingreso" nombre="Recibos de Ingresos"/>
const TableAjusteInv = <Table campos = {camposAjusteInv} detalle="ajusteinv" entidad="ajusteinv" nombre="Ajuste de Inventario"/>
const TableCompra = <Table campos = {camposCompra} detalle="compra" entidad="compra" nombre="Compras"/>
const TableCK = <Table campos = {camposCK} detalle="cheque" entidad="cheque" nombre="Cheques"/>
const TableBanco = <Table campos = {camposBanco} detalle="banco" entidad="banco" nombre="Bancos"/>
const TableCatalogo = <Table campos = {camposCatalogo} detalle="catalogo" entidad="catalogo" nombre="Catálogo Contable"/>
const TableVendedor = <Table campos = {camposVendedor} detalle="vendedor" entidad="vendedor" nombre="Vendedores"/>
const TableCobrador = <Table campos = {camposCobrador} detalle="cobrador" entidad="cobrador" nombre="Cobradores"/>
const TableNotaCxc = <Table campos = {camposNota} detalle="notacxc" entidad="nota/cxc" nombre="Notas de CXC"/>
const TableNotaCxp = <Table campos = {camposNota} detalle="notacxp" entidad="nota/cxp" nombre="Notas de CXP"/>

const RptClt = <FsReport campos = {camposRptClt} detalle="cliente" entidad="cliente" nombre="Listado de Clientes" />
const RptProd = <FsReport campos = {camposProd} entidad="prod"  nombre="Listado de Productos"/>
const RptSuplidor = <FsReport campos = {camposSup} entidad="suplidor"  nombre="Listado de Suplidores"/>
const RptCobrador = <FsReport campos = {camposCobrador} entidad="cobrador"  nombre="Listado de Cobradores"/>
const RptVendedor = <FsReport campos = {camposVendedor} entidad="vendedor"  nombre="Listado de Vendedores"/>

const rutas = [
    { menu:true, modulo: 1, grupo: 'mnt',nombre:'Clientes', ruta: '/cliente', icono: <FaSpider /> ,elemento:TableClt },
    { menu:true, modulo: 2, grupo: 'mnt',nombre:'Productos',ruta: '/producto',icono: <FaSpider /> ,elemento:TableProd},
    { menu:true, modulo: 3, grupo: 'mnt',nombre:'Suplidores',ruta: '/suplidor',icono: <FaSpider />,elemento:TableSup},
    { menu:true, modulo: 4, grupo: 'mnt',nombre:'Compania',ruta: '/compania',icono: <FaSpider /> ,elemento:<MComp/>},
    { menu:true, modulo: 5, grupo: 'mnt',nombre:'NCF',ruta: '/ncf',icono: <FaSpider /> ,elemento:TableNcf},
    { menu:true, modulo: 6, grupo: 'mnt',nombre:'Tipo Cliente',ruta: '/tcliente', icono: <FaSpider /> ,elemento:TableTClt},
    { menu:true, modulo: 7, grupo: 'mnt',nombre:'Tipo Producto',ruta: '/tprod',icono: <FaSpider /> ,elemento:TableTProd},
    { menu:true, modulo: 8, grupo: 'mnt',nombre:'Tipo Suplidor',ruta: '/tsup', icono: <FaSpider />,elemento:TableTSup},
    { menu:true, modulo: 9, grupo: 'mnt',nombre:'Tipo NCF',ruta: '/tncf',icono: <FaSpider />,elemento:TableTncf},
    { menu:true, modulo: 10, grupo: 'mnt',nombre:'Banco',ruta: '/banco',icono: <FaSpider />,elemento:TableBanco},
    { menu:true, modulo: 11, grupo: 'mnt',nombre:'Catalogo',ruta: '/catalogo',icono: <FaSpider />,elemento:TableCatalogo},
    { menu:true, modulo: 12, grupo: 'mnt',nombre:'Vendedor',ruta: '/vendedor',icono: <FaSpider />,elemento:TableVendedor},
    { menu:true, modulo: 13, grupo: 'mnt',nombre:'Cobrador',ruta: '/cobrador',icono: <FaSpider />,elemento:TableCobrador},

    { menu:true, modulo: 14, grupo: 'tran',nombre:'Facturas',ruta: '/venta',icono: <FaSpider />,elemento:TableVenta, roles:[14] },
    { menu:true, modulo: 15, grupo: 'tran',nombre:'Recibos',ruta: '/recibo',icono: <FaSpider />,elemento:TableIng, roles:[15]},
    { menu:true, modulo: 16, grupo: 'tran',nombre:'Compras',ruta: '/compra',icono: <FaSpider />,elemento:TableCompra},
    { menu:true, modulo: 17, grupo: 'tran',nombre:'Cheques',ruta: '/cheque',icono: <FaSpider />,elemento:TableCK},
    { menu:true, modulo: 18, grupo: 'tran',nombre:'Ajuste Inv.',ruta: '/ajusteinv',icono: <FaSpider />,elemento:TableAjusteInv},
    { menu:true, modulo: 19, grupo: 'tran',nombre:'Notas CxC.',ruta: '/notacxc',icono: <FaSpider />,elemento:TableNotaCxc},
    { menu:true, modulo: 20, grupo: 'tran',nombre:'Notas CxP.',ruta: '/notacxp',icono: <FaSpider />,elemento:TableNotaCxp},

    { menu:false, modulo: 28, grupo: '' ,nombre:'dashboard',ruta: '/dashboard',icono: <FaSpider />, elemento: <Dashboard />,roles:[1]},  
    { menu:false, modulo: 28, grupo: '' ,nombre:'dashboard',ruta: '/',icono: <FaSpider />, elemento: <Dashboard/>,roles:[1]},  

    { menu:true, modulo: 29, grupo: 'mntp',nombre:'Clientes', ruta: '/cliente/:id', icono: <FaSpider /> ,elemento:<MCliente/> },
    { menu:true, modulo: 30, grupo: 'mntp',nombre:'Productos',ruta: '/producto/:id',icono: <FaSpider /> ,elemento:<MProd/>},
    { menu:true, modulo: 31, grupo: 'mntp',nombre:'Suplidores',ruta: '/suplidor/:id',icono: <FaSpider />,elemento:<MSup/>},
    
    { menu:true, modulo: 33, grupo: 'mntp',nombre:'NCF',ruta: '/ncf/:id',icono: <FaSpider /> ,elemento:<MNcf/>},
    { menu:true, modulo: 34, grupo: 'mntp',nombre:'Tipo Cliente',ruta: '/tcliente/:id', icono: <FaSpider /> ,elemento:<MTClt/>},
    { menu:true, modulo: 35, grupo: 'mntp',nombre:'Tipo Producto',ruta: '/tprod/:id',icono: <FaSpider /> ,elemento:<MTProd/>},
    { menu:true, modulo: 36, grupo: 'mntp',nombre:'Tipo Suplidor',ruta: '/tsup/:id', icono: <FaSpider />,elemento:<MTSup/>},
    { menu:true, modulo: 37, grupo: 'mntp',nombre:'Tipo NCF',ruta: '/tncf/:id',icono: <FaSpider />,elemento:<MTNcf/>},
    { menu:true, modulo: 38, grupo: 'mntp',nombre:'Banco',ruta: '/banco/:id',icono: <FaSpider />,elemento:<MBanco/>},
    { menu:true, modulo: 39, grupo: 'mntp',nombre:'Catalogo',ruta: '/catalogo/:id',icono: <FaSpider />,elemento:<MCatalogo/>},
    { menu:true, modulo: 40, grupo: 'mntp',nombre:'Vendedor',ruta: '/vendedor/:id',icono: <FaSpider />,elemento:<MVendedor/>},
    { menu:true, modulo: 41, grupo: 'mntp',nombre:'Cobrador',ruta: '/cobrador/:id',icono: <FaSpider />,elemento:<MCobrador/>},

    { menu:true, modulo: 42, grupo: 'tranp',nombre:'Facturas',ruta: '/venta/:id',icono: <FaSpider />, elemento:<TFact/>},
    { menu:true, modulo: 43, grupo: 'tranp',nombre:'Recibos',ruta: '/recibo/:id',icono: <FaSpider />,elemento:<TRec/>},
    { menu:true, modulo: 44, grupo: 'tranp',nombre:'Compras',ruta: '/compra/:id',icono: <FaSpider />,elemento:<TCompra/>},
    { menu:true, modulo: 45, grupo: 'tranp',nombre:'Cheques',ruta: '/cheque/:id',icono: <FaSpider />,elemento:<TCheque/>},
    { menu:true, modulo: 46, grupo: 'tranp',nombre:'Ajuste Inv.',ruta: '/ajusteinv/:id',icono: <FaSpider />,elemento:<TAjusteInv/>},
    { menu:true, modulo: 47, grupo: 'tranp',nombre:'Notas CxC.',ruta: '/notacxc/:id',icono: <FaSpider />,elemento:<MNota NotaEntidad='clt'/>},
    { menu:true, modulo: 48, grupo: 'tranp',nombre:'Notas CxP.',ruta: '/notacxp/:id',icono: <FaSpider />,elemento:<MNota NotaEntidad='sup'/>},

    { menu:true, modulo: 49, grupo: 'rpt',nombre:'Lista clientes',ruta: '/rptcliente',icono: <FaSpider />, elemento:RptClt},
    { menu:true, modulo: 50, grupo: 'rpt',nombre:'Lista productos',ruta: '/rptproducto',icono: <FaSpider />, elemento:RptProd},
    { menu:true, modulo: 51, grupo: 'rpt',nombre:'Lista suplidores',ruta: '/rptsuplidor',icono: <FaSpider />, elemento:RptSuplidor},
    { menu:true, modulo: 52, grupo: 'rpt',nombre:'Lista cobradores',ruta: '/rptcobrador',icono: <FaSpider />, elemento:RptCobrador},
    { menu:true, modulo: 53, grupo: 'rpt',nombre:'Lista vendedores',ruta: '/rptvendedor',icono: <FaSpider />, elemento:RptVendedor},
];
export default rutas;