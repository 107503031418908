import { useContext } from "react";
import { useLocation,Navigate, Outlet } from "react-router-dom";
import { AuthContext  } from '../../context/AuthContext';

//Se envía el código del rol para ser comparado por cada pagina
const RequireAuth = ({allowedRoles}) => {
    const { state } = useContext(AuthContext);
    const location  = useLocation();
    return (

        // se compara el rol de la pagina con los roles que tiene ese usuario.
        state?.user?.access?.length>0
        ? 
            state?.user?.access?.find(role => allowedRoles?.includes(role))
            ? 
                <Outlet/>
            : 
                <Navigate to="/noauth" state={{ from: location }} replace />
        : 
            <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;