import { Suspense, lazy } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuth from "./Auth/RequireAuth";
import rutas from "../router"

const NoFound = lazy(() => import("../views/NoFound"));
const LayoutAdm = lazy(() => import("./Layout"));
// const RequireAuth = lazy(() => import("./Auth/RequireAuth"));
const Login = lazy(() => import("./Auth/Login"));
const NoAuthorized = lazy(() => import("../views/NoAuthorized"));
const LayoutNoAuth = lazy(() => import("./LayoutNoAuth"));
// const Dashboard = lazy(() => import("../views/Dashboard"));

const DynamicRoutes = () => {

    return (
        <BrowserRouter>
            <Suspense fallback={<>Loading ...</>}>
                <Routes>
                    <Route element={<LayoutAdm />}>
                    {rutas.map(r => {
                        return (
                            r.roles?.length>0 ? 
                                    <Route key={r.modulo} element={
                                        <RequireAuth allowedRoles={r.roles} /> 
                                    }>
                                        <Route path={r.ruta} element = {r.elemento} />
                                    </Route>
                            :
                                <Route path={r.ruta} element = {
                                    <Suspense fallback={<>...</>}>
                                        {r.elemento}
                                    </Suspense>
                                } key={r.modulo}/>
                        )
                    })}
                        <Route path="/noauth" element={<Suspense fallback={<>...</>}><NoAuthorized /></Suspense>}></Route>
                    </Route>
                    <Route element={<LayoutNoAuth />}>
                        <Route path="/login" element={<Suspense fallback={<>...</>}><Login /></Suspense>}></Route>
                        <Route path="*" element={<Suspense fallback={<>...</>}><NoFound /></Suspense>}></Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default DynamicRoutes
