// AuthContext.js
import { createContext, useReducer, useEffect } from 'react';

// Initial state for the authentication context
const initialState = {
  isAuthenticated: false,
  user: null,
};

// Action types
const LOGIN = 'LOGIN_SUCCESS';
const LOGOUT = 'LOGOUT';

// Reducer function to handle state transitions
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider component to wrap around the root component
const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  // Read from localStorage during initialization
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      dispatch({ type: LOGIN, payload: JSON.parse(storedUser) });
    }
  }, []);

  // Update localStorage whenever the authentication state changes
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state.user));
  }, [state]);

  const login = (user) => {
    dispatch({ type: LOGIN, payload: user });
  }

  const logout = () => {
    dispatch({ type: LOGOUT});
  }

  return (
    <AuthContext.Provider value={{ state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider};